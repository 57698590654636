export const setCurrencyDisp = (symbol) => {
  const cur1 = symbol.substring(0, 3);
  const cur2 = symbol.substring(3, symbol.length);
  const icon1 = `assets/currency_and_mine_icons/${cur1}.png`;
  const icon2 = `assets/currency_and_mine_icons/${cur2}.png`;
  return { cur1, cur2, icon1, icon2 };
};

export const setMineDisp = (symbol) => {
  let cur1 = "";
  let cur2 = "";
  let icon1 = "";
  let icon2 = "";
  if (symbol === "ONS") {
    cur1 = "ONS";
    icon1 = "assets/currency_and_mine_icons/XAU.png";
  } else if (symbol === "HAS") {
    cur1 = "HAS ALTIN";
    icon1 = "assets/currency_and_mine_icons/XAU.png";
  } else if (symbol.includes("AYAR")) {
    cur1 = symbol.substring(0, 2) + " " + symbol.substring(2, symbol.length);
    icon1 = "assets/currency_and_mine_icons/XAU.png";
  } else if (symbol === "PLATIN" || symbol === "PALADYUM") {
    cur1 = symbol;
    icon1 = "assets/currency_and_mine_icons/XAG.png";
  } else {
    cur1 = symbol.substring(0, symbol.length - 3);
    cur2 = symbol.substring(symbol.length - 3, symbol.length);
    if (cur1 === "XPT" || cur1 === "XPD") {
      icon1 = "assets/currency_and_mine_icons/XAG.png";
    } else {
      icon1 = `assets/currency_and_mine_icons/${cur1}.png`;
    }
    icon2 = `assets/currency_and_mine_icons/${cur2}.png`;
  }
  return { cur1, cur2, icon1, icon2 };
};

export const setSarrDisp = (symbol) => {
  let cur1 = symbol;
  const icon1 = "assets/currency_and_mine_icons/XAU.png";
  if (cur1.includes("CUMHURIYET")){
    cur1 = cur1.replace("(CUMHURIYET)", "")
  }
  return { cur1, icon1 };
};

export const setGramDisp = (symbol) => {
  const icon1 = "assets/currency_and_mine_icons/XAU.png";
  let cur1 = "";
  if (symbol === "HASGRAM") {
    cur1 = "HAS ALTIN";
  } else if (symbol.includes("AYAR")) {
    cur1 = symbol.substring(0, 2) + " " + symbol.substring(2, symbol.length);
  } else {
    cur1 = symbol;
  }
  return { cur1, icon1 };
};
