export const calcCur = (singleData) => {
  let buyValue = 0;
  let sellValue = 0;
  const change =
    ((singleData["sell"] - singleData["sell_open"]) / singleData["sell_open"]) *
    100;
  if (singleData["is_percentage"]) {
    buyValue = singleData["buy"] * (singleData["buy_percentage"] / 100 + 1);
    sellValue = singleData["sell"] * (singleData["sell_percentage"] / 100 + 1);
  } else {
    buyValue = singleData["buy"] + singleData["tl_buy"];
    sellValue = singleData["sell"] + singleData["tl_sell"];
  }
  return { buyValue, sellValue, change };
};

export const calcSarr = (singleData, goldCalcData) => {
  const usd = goldCalcData[0];
  const ons = goldCalcData[1];
  const has = goldCalcData[2];
  let buyValue = 0;
  let sellValue = 0;
  let sellOpen = 0;
  if (singleData["is_fast_milyem"]) {
    buyValue =
      has["buy"] * singleData["milyem_fast_buy"] * singleData["weight"];
    sellValue =
      has["sell"] * singleData["milyem_fast_sell"] * singleData["weight"];
    sellOpen =
      has["sell_open"] * singleData["milyem_fast_sell"] * singleData["weight"];
  } else {
    buyValue =
      (ons["buy"] *
        32.15 *
        usd["buy"] *
        singleData["milyem_buy"] *
        singleData["weight"]) /
      1000;
    sellValue =
      (ons["sell"] *
        32.15 *
        usd["sell"] *
        singleData["milyem_sell"] *
        singleData["weight"]) /
      1000;
    sellOpen =
      (ons["sell_open"] *
        32.15 *
        usd["sell_open"] *
        singleData["milyem_sell"] *
        singleData["weight"]) /
      1000;
  }

  const change = ((sellValue - sellOpen) / sellOpen) * 100;
  return { buyValue, sellValue, change };
};

export const calcGram = (singleData, goldCalcData) => {
  const usd = goldCalcData[0];
  const ons = goldCalcData[1];
  const has = goldCalcData[2];
  let weight = 0;
  let buyValue = 0;
  let sellValue = 0;
  let sellOpen = 0;

  if (singleData["symbol"].includes("AYAR") || singleData["symbol"] === "HASGRAM") {
    weight = 1;
  } else {
    weight = parseFloat(singleData["symbol"].split(" ")[0]);
  }
  if (singleData["is_fast_milyem"]) {
    buyValue = has["buy"] * singleData["milyem_fast_buy"] * weight;
    sellValue = has["sell"] * singleData["milyem_fast_sell"] * weight;
    sellOpen = has["sell_open"] * singleData["milyem_fast_sell"] * weight;
  } else {
    buyValue =
      (ons["buy"] * 32.15 * usd["buy"] * singleData["milyem_buy"] * weight) /
      1000;
    sellValue =
      (ons["sell"] * 32.15 * usd["sell"] * singleData["milyem_sell"] * weight) /
      1000;
    sellOpen =
      (ons["sell_open"] *
        32.15 *
        usd["sell_open"] *
        singleData["milyem_sell"] *
        weight) /
      1000;
  }

  const change = ((sellValue - sellOpen) / sellOpen) * 100;
  return { buyValue, sellValue, change };
};
