import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { TableRow, TableCell, Box } from "@mui/material";
import ThemeContext from "../../../../context/ThemeModeCtx";
import { useContext } from "react";

import "../DataRow.css";
import { calcCur } from "../../../../functions/priceCalculator";
import { setMineDisp } from "../../../../functions/dispNameIconSetter";

export const MineRow = ({ singleData, singleDataPrev }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const dispValues = setMineDisp(singleData["symbol"]);
  const prices = calcCur(singleData);
  let prevPrices = { buyValue: 0, sellValue: 0 };
  if (typeof singleDataPrev === "undefined") {
    prevPrices = { buyValue: 0, sellValue: 0 };
  } else {
    prevPrices = calcCur(singleDataPrev);
  }
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  return (
    <TableRow
      style={{
        height: "10px !important",
        backgroundColor: theme === "dark" && "#2E2E30",
      }}
      className={`${
        parseFloat(
          prices["sellValue"].toFixed(5 - prices["sellValue"].toFixed(0).length)
        ) >=
        parseFloat(
          prevPrices["buyValue"].toFixed(
            5 - prevPrices["buyValue"].toFixed(0).length
          )
        )
          ? "breath-green"
          : "breath-red"
      }`}
    >
      <TableCell
        align="left"
        style={{
          height: "10px !important",
          padding: "0px",
          fontWeight: "bold",
        }}
        size="small"
        sx={{ borderBottomColor: theme === "dark" && "#FFFFFF !important" }}
      >
        {dispValues["cur1"] === "HAS ALTIN" || dispValues["cur1"] === "ONS" ? (
          isMobile ? (
            <div
              className="card-data-currency"
              style={{
                color: theme === "dark" && "#E7F6F1",
                borderBottomColor: theme === "dark" && "#FFFFFF !important",
                fontWeight: "bold",

              }}
            >
              {dispValues["cur1"]}
            </div>
          ) : (
            <div
              className="card-data-currency "
              style={{
                color: theme === "dark" && "#E7F6F1",
                borderBottomColor: theme === "dark" && "#FFFFFF !important",
                fontWeight: "bold",

              }}
            >
              <Box
                component="img"
                src={dispValues["icon1"]}
                sx={{ height: "40%", alignSelf: "center" }}
              />
              {dispValues["cur1"]}
            </div>
          )
        ) : isMobile ? (
          <div
            className="card-data-currency"
            style={{ color: theme === "dark" && "#E7F6F1" }}
          >
            {dispValues["cur1"]}
            <p>/</p>
            {dispValues["cur2"]}
          </div>
        ) : (
          <div
            className="card-data-currency"
            style={{ color: theme === "dark" && "#E7F6F1" }}
          >
            <Box
              component="img"
              src={dispValues["icon1"]}
              sx={{
                height: "40%",
                alignSelf: "center",
                color: theme === "dark" && "#E7F6F1",
              }}
            />
            {dispValues["cur1"]}
            <Box
              component="img"
              src={dispValues["icon2"]}
              sx={{ height: "40%", alignSelf: "center" }}
            />
            {dispValues["cur2"]}
          </div>
        )}
      </TableCell>
      <TableCell
        align="left"
        className="card-data-currency__value"
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {parseFloat(
          prices["buyValue"].toFixed(5 - prices["buyValue"].toFixed(0).length)
        ) >=
        parseFloat(
          prevPrices["buyValue"].toFixed(
            5 - prevPrices["buyValue"].toFixed(0).length
          )
        ) ? (
          <img src="assets/arrows/upward.png" alt="" />
        ) : (
          <img src="assets/arrows/downward.png" alt="" />
        )}
        {singleData["symbol"] === "EURUSD"
          ? prices["buyValue"].toFixed(5 - prices["buyValue"].toFixed(0).length)
          : singleData["symbol"] === "HAS"
          ? prices["buyValue"].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : prices["buyValue"]
              .toFixed(5 - prices["buyValue"].toFixed(0).length)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
      <TableCell
        align="left"
        className="card-data-currency__value"
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {parseFloat(
          prices["sellValue"].toFixed(5 - prices["sellValue"].toFixed(0).length)
        ) >=
        parseFloat(
          prevPrices["sellValue"].toFixed(
            5 - prevPrices["sellValue"].toFixed(0).length
          )
        ) ? (
          <img src="assets/arrows/upward.png" alt="" />
        ) : (
          <img src="assets/arrows/downward.png" alt="" />
        )}
        {singleData["symbol"] === "EURUSD"
          ? prices["sellValue"].toFixed(
              5 - prices["buyValue"].toFixed(0).length
            )
          : singleData["symbol"] === "HAS"
          ? prices["sellValue"].toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          : prices["sellValue"]
              .toFixed(5 - prices["sellValue"].toFixed(0).length)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      </TableCell>
      {/* <TableCell
        align="left"
        className="card-data-currency__value"
        sx={{ display: `${isMobile ? "none" : ""}` }}
      >
        {prices["change"] === Infinity ? (
          <div>-</div>
        ) : (
          <div>
            {prices["change"] > 0 ? (
              <img src="assets/arrows/upward.png" alt="" />
            ) : (
              <img src="assets/arrows/downward.png" alt="" />
            )}
            {`% ${Math.abs(prices["change"]).toFixed(2)}`}
          </div>
        )}
      </TableCell> */}
    </TableRow>
  );
};

MineRow.propTypes = {
  singleData: PropTypes.object,
  singleDataPrev: PropTypes.object,
};

export default MineRow;
