import "./App.css";
import store from "./store";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";

import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function App() {
  return (
    <Provider store={store}>
      <Fragment>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutUs" element={<AboutUs />} />
          </Routes>
        </Router>
      </Fragment>
    </Provider>
  );
}

export default App;
