import React from "react";
import { useContext } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { TableRow, TableCell } from "@mui/material";
import ThemeContext from "../../../../context/ThemeModeCtx";
import "../DataRow.css";
import { calcCur } from "../../../../functions/priceCalculator";
import { setCurrencyDisp } from "../../../../functions/dispNameIconSetter";

export const CurrencyRow = ({ singleData, singleDataPrev }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const dispValues = setCurrencyDisp(singleData["symbol"]);
  const prices = calcCur(singleData);
  let prevPrices = { buyValue: 0, sellValue: 0 };
  if (typeof singleDataPrev === "undefined") {
    prevPrices = { buyValue: 0, sellValue: 0 };
  } else {
    prevPrices = calcCur(singleDataPrev);
  }
  // console.log(singleData["symbol"], prices["buyValue"], prevPrices["buyValue"]);
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  return (
    <TableRow
      style={{
        height: "10px !important",
        backgroundColor: theme === "dark" && "#2E2E30",
      }}
      className={`${
        parseFloat(prices["sellValue"].toFixed(3)) >=
        parseFloat(prevPrices["sellValue"].toFixed(3))
          ? "breath-green"
          : "breath-red"
      }`}
    >
      <TableCell
        size="small"
        align="left"
        style={{
          height: "10px !important",
          padding: "0px !important",
        }}
        sx={{ borderBottomColor: theme === "dark" && "#FFFFFF !important" }}
      >
        {isMobile ? (
          <div
            className="card-data-currency"
            style={{
              color: theme === "dark" && "#E7F6F1",
              borderBottomColor: theme === "dark" && "#FFFFFF !important",
              fontWeight: "bold",

            }}
          >
            {dispValues["cur1"]}
            <p>/</p>
            {dispValues["cur2"]}
          </div>
        ) : (
          <div
            className="card-data-currency"
            style={{
              fontSize: "27px",
              color: theme === "dark" && "#E7F6F1",
              borderBottomColor: theme === "dark" && "#FFFFFF !important",
              fontWeight: "bold",
            }}
          >
            <img src={dispValues["icon1"]} alt="" />
            {dispValues["cur1"]} <img src={dispValues["icon2"]} alt="" />
            {dispValues["cur2"]}
          </div>
        )}
      </TableCell>
      <TableCell
        align="left"
        className="card-data-currency__value"
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {parseFloat(prices["buyValue"].toFixed(3)) >=
        parseFloat(prevPrices["buyValue"].toFixed(3)) ? (
          <img src="assets/arrows/upward.png" alt="" />
        ) : (
          <img src="assets/arrows/downward.png" alt="" />
        )}
        {prices["buyValue"].toFixed(3)}
      </TableCell>
      <TableCell
        align="left"
        className="card-data-currency__value "
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {parseFloat(prices["sellValue"].toFixed(3)) >=
        parseFloat(prevPrices["sellValue"].toFixed(3)) ? (
          <img src="assets/arrows/upward.png" alt="" />
        ) : (
          <img src="assets/arrows/downward.png" alt="" />
        )}
        {prices["sellValue"].toFixed(3)}
      </TableCell>
      {/* <TableCell
        sx={{ display: `${isMobile ? "none" : ""}` }}
        align="left"
        className="card-data-currency__value  card-data-degisim__value"
      >
        {prices["change"] === Infinity ? (
          <div>-</div>
        ) : (
          <div>
            {prices["change"] > 0 ? (
              <img src="assets/arrows/upward.png" alt="" />
            ) : (
              <img src="assets/arrows/downward.png" alt="" />
            )}
            {`% ${Math.abs(prices["change"]).toFixed(2)}`}
          </div>
        )}
      </TableCell> */}
    </TableRow>
  );
};

CurrencyRow.propTypes = {
  singleData: PropTypes.object,
  singleDataPrev: PropTypes.object,
};

export default CurrencyRow;
