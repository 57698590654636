import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
} from "@mui/material";
import ThemeContext from "../../../context/ThemeModeCtx";
import "./DataTable.css";
import "../../../App.css";
import DataRow from "./DataRow";

export const DataTable = ({ cardType, curData, curDataPrev }) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  return (
    <TableContainer sx={{ maxHeight: 800 }}>
      <Table className="tablecell" stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow
            sx={{ backgroundColor: theme === "dark" && "#2E2E30 !important" }}
          >
            <TableCell
              className="tablecell"
              sx={{
                backgroundColor: theme === "dark" && "#2E2E30 !important",
                width: "40% !important",
              }}
            ></TableCell>
            <TableCell
              align="left"
              className="tablecell"
              sx={{
                backgroundColor: theme === "dark" && "#2E2E30 !important",
                color: theme === "dark" && "white !important",
                width: "30%",
              }}
            >
              ALIŞ
            </TableCell>
            <TableCell
              align="left"
              className="tablecell"
              sx={{
                backgroundColor: theme === "dark" && "#2E2E30 !important",
                color: theme === "dark" && "white !important",
                width: "30%",
              }}
            >
              SATIŞ
            </TableCell>
            {/* <TableCell
              align="center"
              className="tablecell"
              sx={{ display: `${isMobile ? "none" : ""}` }}
            >
              DEĞİŞİM
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody className="table-body">
          {curData?.map((value) => (
            <DataRow
              key={value["symbol"]}
              cardType={cardType}
              singleData={value}
              singleDataPrev={
                curDataPrev[
                  curDataPrev.findIndex(
                    (item) => item.symbol === value["symbol"]
                  )
                ]
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DataTable.propTypes = {
  cardType: PropTypes.string.isRequired,
  curData: PropTypes.array,
  curDataPrev: PropTypes.array,
};

export default DataTable;
