import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Select, MenuItem, TextField } from "@mui/material";
import ThemeContext from "../../context/ThemeModeCtx";
import "./Converter.css";
import { setMineDisp } from "../../functions/dispNameIconSetter";
import { calcCur } from "../../functions/priceCalculator";
import { makeStyles } from "@mui/styles";

export const MineConverter = ({ curData }) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  const useStyles = makeStyles({
    input: {
      color: theme === "dark" && "#DEDEDE !important",
    },
  });
  const classes = useStyles();

  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      border: 0,
      padding: 0,
      height: "auto",
      background: "red",
      "&:hover": {
        boxShadow: "none",
      },
    }),
  };
  let dispValues = curData.map((x) => setMineDisp(x.symbol));
  // console.log(dispValues);
  // dispValues = dispValues.filter((obj) => {
  //   return obj.cur1 !== "HAS ALTIN";
  // });
  // console.log(dispValues);
  let uniqueCur1s = [...new Set(dispValues.map((x) => x["cur1"]))];
  let uniqueCur2s = [...new Set(dispValues.map((x) => x["cur2"]))];
  let deletedCur1s = [
    "ONS",
    "HAS ALTIN",
    "USD",
    "EUR",
    "14 AYAR",
    "22 AYAR",
    "PLATIN",
    "PALADYUM",
    "",
  ];
  let deletedCur2s = ["", "TRY"];
  uniqueCur1s = uniqueCur1s.filter((item) => !deletedCur1s.includes(item));
  uniqueCur2s = uniqueCur2s.filter((item) => !deletedCur2s.includes(item));
  uniqueCur2s.push("TRY");
  const [cur1, setCur1] = useState(uniqueCur1s[0]);
  const [cur2, setCur2] = useState(uniqueCur2s[0]);
  const [input, setInput] = useState(0);

  const symbol = cur1 + cur2;
  const curIndex = curData.findIndex((item) => item.symbol === symbol);
  let prices = { buyValue: 0, sellValue: 0 };
  if (curIndex !== -1) {
    prices = calcCur(curData[curIndex]);
  }
  const onCur1hange = (event) => {
    setCur1(event.target.value);
    setInput(0);
  };
  const onCur2hange = (event) => {
    setCur2(event.target.value);
    setInput(0);
  };
  const onInputChange = (event) => {
    setInput(parseFloat(event.target.value));
    if (curIndex === -1) {
      alert("Bu birimleri su anda hesaplayamiyoruz...");
    }
  };
  return (
    <div className="converterWrapper">
      <div className="input-container">
        <Select
          value={cur1}
          onChange={onCur1hange}
          styles={customStyles}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          //  sx={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
        >
          {uniqueCur1s?.map((value) => (
            <MenuItem key={uniqueCur1s.indexOf(value)} value={value}>
              <img
                src={
                  dispValues[
                    dispValues.findIndex((item) => item.cur1 === value)
                  ]["icon1"]
                }
                alt=""
              />
              {value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          className="deneme"
          type="number"
          onChange={onInputChange}
          value={input}
          sx={{ m: 0, p: 0 }}
          // inputProps={{ className: classes.input }}
        />
        <Select
          value={cur2}
          onChange={onCur2hange}
          styles={customStyles}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          //  sx={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
        >
          {uniqueCur2s?.map((value) => (
            <MenuItem key={uniqueCur2s.indexOf(value)} value={value}>
              <img
                src={
                  dispValues[
                    dispValues.findIndex((item) => item.cur2 === value)
                  ]["icon2"]
                }
                alt=""
              />
              {value}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className="results-container">
        <TextField
          //  InputLabelProps={{
          //    sx: { ...(theme === "dark" && { color: "#F9F9F9 !important" }) },
          //  }}
          // inputProps={{ className: classes.input }}
          value={
            (prices["buyValue"] * input).toFixed(0).length > 5 || input === 0
              ? (prices["buyValue"] * input)
                  .toFixed(0)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : (prices["buyValue"] * input)
                  .toFixed(5 - (prices["buyValue"] * input).toFixed(0).length)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          label="Alis Fiyati"
          className="deneme"
        />
        <TextField
          className="deneme"
          // inputProps={{ className: classes.input }}
          //   InputLabelProps={{
          //       sx: { ...(theme === "dark" && { color: "#F9F9F9 !important" }) },
          //    }}
          value={
            (prices["sellValue"] * input).toFixed(0).length > 5 || input === 0
              ? (prices["sellValue"] * input)
                  .toFixed(0)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : (prices["sellValue"] * input)
                  .toFixed(5 - (prices["sellValue"] * input).toFixed(0).length)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          label="Satis Fiyati"
        />
      </div>
    </div>
  );
};

MineConverter.propTypes = {
  curData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  curData: state.curState.mine,
});

export default connect(mapStateToProps)(MineConverter);
