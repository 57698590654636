import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { TableRow, TableCell } from "@mui/material";
import "../DataRow.css";
import ThemeContext from "../../../../context/ThemeModeCtx";
import { useContext } from "react";
import { setSarrDisp } from "../../../../functions/dispNameIconSetter";
export const BulkSarrafiyeRow = ({ singleData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const dispValues = setSarrDisp(singleData["symbol"]);
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  return (
    <TableRow
      style={{
        height: "10px !important",
        backgroundColor: theme === "dark" && "#2E2E30",
      }}
    >
      <TableCell
        sx={{ borderBottomColor: theme === "dark" && "#FFFFFF !important" }}
      >
        {isMobile ? (
          <div
            className="card-data-currency"
            style={{
              color: theme === "dark" && "#E7F6F1",
              borderBottomColor: theme === "dark" && "#FFFFFF !important",
              fontWeight: "bold",
            }}
          >
            {dispValues["cur1"]}
          </div>
        ) : (
          <div
            className="card-data-currency"
            style={{
              color: theme === "dark" && "#E7F6F1",
              borderBottomColor: theme === "dark" && "#FFFFFF !important",
              fontWeight: "bold",
            }}
          >
            <img src={dispValues["icon1"]} alt="" />
            {dispValues["cur1"]}
          </div>
        )}
      </TableCell>
      <TableCell
        align="left"
        className="card-data-currency__value"
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {singleData["milyem_bulk_buy"].toFixed(3)}{" "}
      </TableCell>
      <TableCell
        align="left"
        className="card-data-currency__value"
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {singleData["milyem_bulk_sell"].toFixed(3)}{" "}
      </TableCell>
      {/* <TableCell
        sx={{ display: `${isMobile ? "none" : ""}` }}
        align="left"
        className="card-data-currency__value card-data-degisim__value"
      >
        -
      </TableCell> */}
    </TableRow>
  );
};

BulkSarrafiyeRow.propTypes = {
  singleData: PropTypes.object,
};

export default BulkSarrafiyeRow;
