import React from "react";
import PropTypes from "prop-types";

import CurrencyRow from "./rows/CurrencyRow";
import MineRow from "./rows/MineRow";
import SarrafiyeRow from "./rows/SarrafiyeRow";
import GramRow from "./rows/GramRow";
import BulkSarrafiyeRow from "./rows/BulkSarrafiyeRow";
import BulkGramRow from "./rows/BulkGramRow";

export const DataRow = ({ cardType, singleData, singleDataPrev }) => {
  if (cardType === "currency" || cardType === "parite") {
    return (
      <CurrencyRow singleData={singleData} singleDataPrev={singleDataPrev} />
    );
  } else if (cardType === "mine") {
    return <MineRow singleData={singleData} singleDataPrev={singleDataPrev} />;
  } else if (cardType === "sarrafiye") {
    return (
      <SarrafiyeRow singleData={singleData} singleDataPrev={singleDataPrev} />
    );
  } else if (cardType === "gram") {
    return <GramRow singleData={singleData} singleDataPrev={singleDataPrev} />;
  } else if (cardType === "toptan sarrafiye") {
    return <BulkSarrafiyeRow singleData={singleData} />;
  } else {
    if (singleData["symbol"] === "USDTRY" || singleData["symbol"] === "EURTRY") {
      return null
    }
    return <BulkGramRow singleData={singleData} />;
  }
};

DataRow.propTypes = {
  cardType: PropTypes.string.isRequired,
  singleData: PropTypes.object,
  singleDataPrev: PropTypes.object,
};

export default DataRow;
