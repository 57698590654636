import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { TableRow, TableCell } from "@mui/material";
import "../DataRow.css";
import ThemeContext from "../../../../context/ThemeModeCtx";
import { setGramDisp } from "../../../../functions/dispNameIconSetter";
import { calcGram, calcCur } from "../../../../functions/priceCalculator";

export const GramRow = ({
  singleData,
  singleDataPrev,
  goldCalcData,
  goldCalcDataPrev,
  userSource,
}) => {
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const dispValues = setGramDisp(singleData["symbol"]);
  let prices = { buyValue: 0, sellValue: 0, change: 0 };
  if (
    userSource === "FOREX" &&
    !(singleData["symbol"] === "HAS") &&
    !(singleData["symbol"] === "USDTRY") &&
    !(singleData["symbol"] === "EURTRY")
  ) {
    prices = calcGram(singleData, goldCalcData);
  } else {
    prices = calcCur(singleData);
  }
  let prevPrices = { buyValue: 0, sellValue: 0 };
  if (typeof singleDataPrev === "undefined") {
    prevPrices = { buyValue: 0, sellValue: 0 };
  } else {
    if (
      userSource === "FOREX" &&
      !(singleData["symbol"] === "HAS") &&
      !(singleData["symbol"] === "USDTRY") &&
      !(singleData["symbol"] === "EURTRY")
    ) {
      prevPrices = calcGram(singleDataPrev, goldCalcDataPrev);
    } else {
      prevPrices = calcCur(singleDataPrev);
    }
  }
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  return (
    <TableRow
      style={{
        height: "10px !important",
        backgroundColor: theme === "dark" && "#2E2E30",
      }}
      className={`${
        parseFloat(prices["sellValue"].toFixed(0)) >=
        parseFloat(prevPrices["sellValue"].toFixed(0))
          ? "breath-green"
          : "breath-red"
      }`}
    >
      <TableCell
        sx={{ borderBottomColor: theme === "dark" && "#FFFFFF !important" }}
      >
        {isMobile ? (
          <div
            className="card-data-currency"
            style={{
              color: theme === "dark" && "#E7F6F1",
              borderBottomColor: theme === "dark" && "#FFFFFF !important",
              fontWeight: "bold",
            }}
          >
            {dispValues["cur1"]}
          </div>
        ) : (
          <div
            className="card-data-currency"
            style={{
              color: theme === "dark" && "#E7F6F1",
              borderBottomColor: theme === "dark" && "#FFFFFF !important",
              fontWeight: "bold",
            }}
          >
            <img src={dispValues["icon1"]} alt="" />
            {dispValues["cur1"]}
          </div>
        )}
      </TableCell>
      <TableCell
        align="right"
        className="card-data-currency__value"
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {parseFloat(prices["buyValue"].toFixed(0)) >=
        parseFloat(prevPrices["buyValue"].toFixed(0)) ? (
          <img src="assets/arrows/upward.png" alt="" />
        ) : (
          <img src="assets/arrows/downward.png" alt="" />
        )}
        {singleData["symbol"] === "USDTRY" ||
        singleData["symbol"] === "EURTRY" ? (
          <>{prices["buyValue"].toFixed(3)}</>
        ) : (
          <>
            {prices["buyValue"]
              .toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </>
        )}
        {/* {prices["buyValue"].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
      </TableCell>
      <TableCell
        align="right"
        className="card-data-currency__value"
        sx={{
          color: theme === "dark" && "#FFFFFF !important",
          borderBottomColor: theme === "dark" && "#FFFFFF !important",
        }}
      >
        {parseFloat(prices["sellValue"].toFixed(0)) >=
        parseFloat(prevPrices["sellValue"].toFixed(0)) ? (
          <img src="assets/arrows/upward.png" alt="" />
        ) : (
          <img src="assets/arrows/downward.png" alt="" />
        )}
        {singleData["symbol"] === "USDTRY" ||
        singleData["symbol"] === "EURTRY" ? (
          <>{prices["sellValue"].toFixed(3)}</>
        ) : (
          <>
            {prices["sellValue"]
              .toFixed(0)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </>
        )}
        {/* {prices["sellValue"].toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} */}
      </TableCell>
      {/* <TableCell
        sx={{ display: `${isMobile ? "none" : ""}` }}
        align="right"
        className="card-data-currency__value card-data-degisim__value"
      >
        {prices["change"] === Infinity ? (
          <div>-</div>
        ) : (
          <div>
            {prices["change"] > 0 ? (
              <img src="assets/arrows/upward.png" alt="" />
            ) : (
              <img src="assets/arrows/downward.png" alt="" />
            )}
            {`% ${Math.abs(prices["change"]).toFixed(2)}`}
          </div>
        )}
      </TableCell> */}
    </TableRow>
  );
};

GramRow.propTypes = {
  singleData: PropTypes.object,
  singleDataPrev: PropTypes.object,
  goldCalcData: PropTypes.array.isRequired,
  goldCalcDataPrev: PropTypes.array.isRequired,
  userSource: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  goldCalcData: state.curState.goldCalcData,
  goldCalcDataPrev: state.curState.goldCalcDataPrev,
  userSource: state.curState.source,
});

export default connect(mapStateToProps)(GramRow);
