import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import DataCard from "../components/layout/card/DataCard";
import NavBar from "../components/layout/header/NavBar";

export const SourceDecider = ({ userSource }) => {
  const [dispCard, setDispCard] = useState(true);
  const [bulkCard, setBulkcard] = useState(false);
  const [doviz, setDoviz] = useState("mine");
  const onClickDoviz = () => {
    setDoviz("currency");
  };
  const onClickMaden = () => {
    setDoviz("mine");
  };
  const onClickParite = () => {
    setDoviz("parite");
  };
  const onClickToptan = () => {
    setBulkcard(true);
  };
  const onClickParekende = () => {
    setBulkcard(false);
  };
  const onClickSarrafiye = () => {
    setDispCard(true);
  };
  const onClickGram = () => {
    setDispCard(false);
  };

  return (
    <div className="column">
      <NavBar
        onClickToptan={onClickToptan}
        onClickPar={onClickParekende}
        userSource={userSource}
      />
      <div className="cards-wrapper">
        {bulkCard ? (
          <>
            <div className="column">
              <DataCard
                cardType={doviz}
                onClickTitle={onClickDoviz}
                onClickTitle2={onClickMaden}
                onClickTitle3={onClickParite}
              />{" "}
            </div>
            {dispCard || userSource === "HAREM" ? (
              <div className="column">
                <DataCard
                  cardType="toptan sarrafiye"
                  onClickTitle={onClickSarrafiye}
                  onClickTitle2={onClickGram}
                />
              </div>
            ) : (
              <div className="column">
                <DataCard
                  cardType="toptan gram"
                  onClickTitle={onClickSarrafiye}
                  onClickTitle2={onClickGram}
                />
              </div>
            )}
          </>
        ) : userSource === "HAREM" ? (
          <div className="column">
            <DataCard
              cardType="sarrafiye"
              onClickTitle={onClickSarrafiye}
              onClickTitle2={onClickGram}
            />
          </div>
        ) : (
          <>
            <div className="column">
              <DataCard
                cardType="gram"
                onClickTitle={onClickSarrafiye}
                onClickTitle2={onClickGram}
                isBulk={bulkCard}
              />
            </div>
            <div className="column">
              <DataCard
                cardType="sarrafiye"
                onClickTitle={onClickSarrafiye}
                onClickTitle2={onClickGram}
                isBulk={bulkCard}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

SourceDecider.propTypes = {
  userSource: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userSource: state.curState.source,
});

export default connect(mapStateToProps)(SourceDecider);
