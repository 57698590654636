import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeContextProvider } from "./context/ThemeModeCtx";
import "./App.css";

ReactDOM.render(
  <ThemeContextProvider>
  <React.StrictMode>
    <App className="app" />
  </React.StrictMode>
  </ThemeContextProvider>,
  document.getElementById("root")
);
