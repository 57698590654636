import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import CurrConverter from "./CurrConverter";
import MineConverter from "./MineConverter";
import SarrConverter from "./SarrConverter";
import GramConverter from "./GramConverter";
import ParConverter from "./ParConverter";

export const Converter = ({ convType, sarrData }) => {
  if (typeof sarrData[0] === "undefined") {
    return <div>Loading...</div>;
  } else {
    if (convType === "currency") {
      return <CurrConverter />;
    } else if (convType === "mine") {
      return <MineConverter />;
    } else if (convType === "sarrafiye") {
      return <SarrConverter />;
    } else if (convType === "gram") {
      return <GramConverter />;
    } else if (convType === "parite") {
      return <ParConverter />;
    } else {
      return null;
    }
  }
};

Converter.propTypes = {
  convType: PropTypes.string.isRequired,
  sarrData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  sarrData: state.curState.sarr,
});

export default connect(mapStateToProps)(Converter);
