import { createContext, useState } from "react";

const ThemeContext = createContext({
  theme : "",
  changeTheme: () => {},
});

export function ThemeContextProvider(props) {
  const [userTheme, setTheme] = useState("light");
  console.log(userTheme)
  function changeTheme() {
   if(userTheme === "light"){
    setTheme("dark")
   } else {
    setTheme("light")
   }
  }
  const context = {
    theme: userTheme,
    changeTheme: changeTheme,
  };
  return (
    <ThemeContext.Provider value={context}>
      {props.children}
    </ThemeContext.Provider>
  );
}

export default ThemeContext;
