import PropTypes from "prop-types";
import { connect } from "react-redux";
import React from "react";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Box,
} from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { useContext } from "react";
import ThemeContext from "../../../context/ThemeModeCtx";
import "../../../App.css";
import "./DataCard.css";
import logo from "../../../assets/LOGO.png";
import DataTable from "./DataTable";

export const DataCard = ({
  onClickTitle,
  onClickTitle2,
  onClickTitle3,
  cardType,
  allCurState,
  isBulk,
}) => {
  let cardTitle = "";
  let secondCardtitle = "";
  let thirdCardtitle = "";
  let active = "card-header";
  let passive = "card-header__passive";
  let class1 = active;
  let class2 = passive;
  let class3 = passive;

  if (cardType === "currency") {
    cardTitle = "DÖVİZ FİYATLARI";
    secondCardtitle = "MADEN FİYATLARI";
    thirdCardtitle = "PARITE FİYATLARI";
    class1 = active;
    class2 = passive;
    class3 = passive;
  } else if (cardType === "mine") {
    cardTitle = "DÖVİZ FİYATLARI";
    secondCardtitle = "MADEN FİYATLARI";
    thirdCardtitle = "PARITE FİYATLARI";
    class1 = passive;
    class2 = active;
    class3 = passive;
  } else if (cardType === "parite") {
    cardTitle = "DÖVİZ FİYATLARI";
    secondCardtitle = "MADEN FİYATLARI";
    thirdCardtitle = "PARITE FİYATLARI";
    class1 = passive;
    class2 = passive;
    class3 = active;
  } else if (cardType.includes("sarrafiye")) {
    cardTitle = "SARRAFİYE";
    secondCardtitle = "GRAM ALTIN";
    class2 = passive;
    class1 = active;
  } else if (cardType.includes("gram")) {
    cardTitle = "SARRAFİYE";
    secondCardtitle = "GRAM ALTIN";
    class1 = passive;
    class2 = active;
  }
  console.log(!(typeof isBulk === "undefined"));
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  return (
    <Card
      className="card"
      sx={{ background: "rgba(255, 255, 255, 0.05) !important" }}
    >
      <div className="card-header-wrapper">
        <CardMedia
          sx={{ paddingTop: "4px", display: `${isMobile ? "none" : ""}` }}
          component="img"
          image={logo}
          height="50px"
          className="card-logo"
        />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-evenly",
          }}
        >
          {typeof isBulk === "undefined" ? (
            <CardHeader
              sx={{ paddingBottom: 0, color: theme === "dark" && "white" }}
              title={cardTitle}
              className={class1}
              onClick={onClickTitle}
            />
          ) : cardType.includes("sarrafiye") ? (
            <CardHeader
              sx={{ paddingBottom: 0, color: theme === "dark" && "white" }}
              title={cardTitle}
              className={class1}
              onClick={onClickTitle}
            />
          ) : (
            <CardHeader
              sx={{ paddingBottom: 0, color: theme === "dark" && "white" }}
              title={secondCardtitle}
              className={class2}
              onClick={onClickTitle}
            />
          )}
          {(allCurState.source === "HAREM" && cardType.includes("sarrafiye")) ||
          !(typeof isBulk === "undefined") ? null : (
            <CardHeader
              sx={{ paddingBottom: 0, color: theme === "dark" && "white" }}
              title={secondCardtitle}
              className={class2}
              onClick={onClickTitle2}
            />
          )}
          {allCurState.source === "FOREX" ||
          cardType.includes("sarrafiye") ||
          !(typeof isBulk === "undefined") ? null : (
            <CardHeader
              sx={{ paddingBottom: 0 }}
              title={thirdCardtitle}
              className={class3}
              onClick={onClickTitle3}
            />
          )}
        </Box>
      </div>
      <CardContent
        className="card-content"
        sx={{ paddingTop: "0px !important" }}
      >
        <DataTable
          cardType={cardType}
          curData={
            cardType === "currency"
              ? allCurState.curr
              : cardType === "mine"
              ? allCurState.mine
              : cardType.includes("sarrafiye")
              ? allCurState.sarr
              : cardType.includes("gram")
              ? allCurState.gram
              : cardType === "parite"
              ? allCurState.parite
              : null
          }
          curDataPrev={
            cardType === "currency"
              ? allCurState.currPrev
              : cardType === "mine"
              ? allCurState.minePrev
              : cardType.includes("sarrafiye")
              ? allCurState.sarrPrev
              : cardType.includes("gram")
              ? allCurState.gramPrev
              : cardType === "parite"
              ? allCurState.paritePrev
              : null
          }
        />
      </CardContent>
    </Card>
  );
};

DataCard.propTypes = {
  onClickTitle: PropTypes.func,
  onClickTitle2: PropTypes.func,
  onClickTitle3: PropTypes.func,
  cardType: PropTypes.string.isRequired,
  allCurState: PropTypes.object,
  isBulk: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  allCurState: state.curState,
});

export default connect(mapStateToProps)(DataCard);
