import React from "react";
import { useContext } from "react";
import Stack from "@mui/material/Stack";
import { Box, Container } from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import ThemeContext from "../../../context/ThemeModeCtx";
import "./Footer.css";
import geoLocationIcon from "../../../assets/GEO-LOCATION-ICON.png";
import geoLocationIconDark from "../../../assets/GEO-LOCATION-ICON-DARK.png";
import mobileIcon from "../../../assets/MOBILE-ICON.png";
import mobileIconDark from "../../../assets/MOBILE-ICON-DARK.png";
import mapImage from "../../../assets/MAP.png";
import netpartnerlogo from "../../../assets/NETPARTNER-LOGO.png";

export default function Footer() {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  return (
    <footer>
      <div className="footer-wrapper">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Stack className="footer-left-column">
            <h2 style={{ marginLeft: 40, color: theme === "dark" && "white" }}>
              İLETİŞİM BİLGİSİ
            </h2>
            <div style={{ display: "flex" }}>
              <Box
                className="location-logo"
                component="img"
                src={theme === "dark" ? geoLocationIconDark : geoLocationIcon}
                alt="location icon"
                style={{
                  alignSelf: "center",
                  marginRight: "10",
                  width: "100%",
                  height: "auto",
                  maxWidth: 25,
                  maxHeight: 45,
                }}
              ></Box>
              <h3
                style={{
                  flexWrap: "nowrap",
                  color: theme === "dark" && "white",
                }}
              >
                Marmara Mah. Hürriyet Blv. Hasan Aslan Plaza <br></br>No:223/6
                Yakuplu<br></br>
                Beylikdüzü, İstanbul
              </h3>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
              <Box
                className="tel-logo"
                component="img"
                maxHeight={"35px"}
                src={theme === "dark" ? mobileIconDark : mobileIcon}
                alt="mobile icon"
                style={{ marginRight: 10, alignSelf: "center" }}
              ></Box>
              <div
                className="tel-numbers-wrapper"
                style={{ color: theme === "dark" && "white !important" }}
              >
                <p style={{ color: theme === "dark" && "white" }}>
                  0212 812 60 10
                </p>
                <p style={{ color: theme === "dark" && "white" }}>
                  0212 812 60 11
                </p>
                <p style={{ color: theme === "dark" && "white" }}>
                  0533 583 60 11
                </p>
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 40,
                  alignItems: "center",
                }}
              >
                <p style={{ color: theme === "dark" && "white" }}>
                  Çok Yakında
                </p>
                <AppleIcon
                  style={{
                    fontSize: 50,
                    alignSelf: "center",
                    color: theme === "dark" && "white",
                  }}
                ></AppleIcon>
                <AndroidIcon
                  style={{
                    fontSize: 50,
                    alignSelf: "center",
                    color: theme === "dark" && "white",
                  }}
                ></AndroidIcon>
              </div>
            </div>
          </Stack>
          <Container
            style={{
              margin: "auto",
              display: "flex",
              justifyContent: "center",
            }}
            className="map-container"
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d385522.8218084616!2d28.391455332812495!3d40.9837352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14b55ff514bda93f%3A0xd484aea1fcea6df2!2sHasan%20Aslan%20Plaza!5e0!3m2!1sen!2str!4v1646230680900!5m2!1sen!2str"
              width="100%"
              height="450"
              style={{ border: 0 }}
              loading="lazy"
            ></iframe>
          </Container>
        </div>
        <Box className="footer-bottom-container">
          <a href="https://netpartner.com.tr/">
            <Box
              className="netpartner-logo"
              component="img"
              src={netpartnerlogo}
              height={25}
            ></Box>
          </a>
          <p>Tüm hakları saklıdır.</p>
          <p>
            Sitemizde yer alan döviz kurları ve altın fiyatları bilgi amaçlıdır.
            Yayınlanan bilgiler yatırım danışmanlığı kapsamında değildir.
          </p>
        </Box>
      </div>
    </footer>
  );
}
