const forexAllCurs = {
  currencies: [
    "USDTRY",
    "EURTRY",
    "GBPTRY",
    "CHFTRY",
    "SARTRY",
    "SEKTRY",
    "NOKTRY",
    "AUDTRY",
    "CADTRY",
  ],
  mines: [
    "HAS",
    "ONS",
    "USDTRY",
    "EURTRY",
    "EURUSD",
    "KGUSD",
    "KGEUR",
    "XAGUSD",
    "GUMUSD",
    "GUMEUR",
    "GUMTRY",
  ],
  sarrafiyeler: [
    "YENI CEYREK",
    "ESKI CEYREK",
    "YENI YARIM",
    "ESKI YARIM",
    "YENI TAM",
    "ESKI TAM",
    "YENI GREMSE",
    "ESKI GREMSE",
    "YENI ATA(CUMHURIYET)",
    "ESKI ATA(CUMHURIYET)",
    "YENI ATA 5",
    "ESKI ATA 5",
    "ESKI RESAT",
    "ESKI RESAT 2.5",
    "ESKI RESAT 5",
  ],
  gramlar: [
    "HASGRAM",
    "USDTRY",
    "EURTRY",
    "1 Gr (24)",
    "22AYAR",
    "1 Gr (22)",
    "18AYAR",
    "14AYAR",
    "0.5 Gr (24)",
    "0.5 Gr (22)",
    "2.5 Gr (24)",
    "5 Gr (24)",
    "10 Gr (24)",
    "20 Gr (24)",
    "50 Gr (24)",
    "100 Gr (24)",
  ],
  pariteler: [],
};

const haremAllCurs = {
  currencies: [
    "USDTRY",
    "EURTRY",
    "EURUSD",
    "JPYTRY",
    "GBPTRY",
    "DKKTRY",
    "SEKTRY",
    "NOKTRY",
    "CHFTRY",
    "AUDTRY",
    "CADTRY",
    "SARTRY",
    "RUBTRY",
    "BGNTRY",
    "AEDTRY",
    "QARTRY",
    "CNYTRY",
  ],
  mines: [
    "HAS",
    "ONS",
    "KGUSD",
    "KGEUR",
    "14AYAR",
    "22AYAR",
    "XAUXAG",
    "XAGUSD",
    "GUMUSD",
    "GUMTRY",
    "XPTUSD",
    "XPDUSD",
    "PLATIN",
    "PALADYUM",
  ],
  sarrafiyeler: [
    "YENI CEYREK",
    "ESKI CEYREK",
    "YENI YARIM",
    "ESKI YARIM",
    "YENI TAM",
    "ESKI TAM",
    "YENI ATA",
    "ESKI ATA",
    "YENI GREMSE",
    "ESKI GREMSE",
    "YENI ATA 5",
    "ESKI ATA 5",
  ],
  gramlar: [],
  pariteler: [
    "GBPUSD",
    "USDCHF",
    "AUDUSD",
    "USDDKK",
    "USDCAD",
    "USDJPY",
    "USDSAR",
    "USDSEK",
    "USDNOK",
    "USDRUB",
    "USDBGN",
    "JODUSD",
    "OMRUSD",
    "USDQAR",
    "AEDUSD",
    "KWDUSD",
    "USDDILS",
    "USDMAD",
  ],
};

const saglamAllCurs = {
  currencies: [
    "USDTRY",
    "EURTRY",
    "EURUSD",
    "GBPTRY",
    "DKKTRY",
    "SEKTRY",
    "NOKTRY",
    "CHFTRY",
    "JPYTRY",
    "AUDTRY",
    "CADTRY",
    "SARTRY",
  ],
  mines: [
    "HAS",
    "ONS",
    "KGUSD",
    "KGEUR",
    "XAGUSD",
    "GUMTRY",
    "GUMUSD",
    "GUMEUR",
  ],
  sarrafiyeler: [
    "YENI CEYREK",
    "ESKI CEYREK",
    "YENI YARIM",
    "ESKI YARIM",
    "YENI TAM",
    "ESKI TAM",
    "YENI GREMSE",
    "ESKI GREMSE",
    "YENI ZIYNET 5",
    "ESKI ZIYNET 5",
    "YENI ATA CEYREK",
    "ESKI ATA CEYREK",
    "YENI ATA YARIM",
    "ESKI ATA YARIM",
    "YENI ATA",
    "ESKI ATA",
    "YENI ATA 2.5",
    "ESKI ATA 2.5",
    "YENI ATA 5",
    "ESKI ATA 5",
    "ESKI RESAT CEYREK",
    "ESKI RESAT YARIM",
    "ESKI RESAT",
    "ESKI RESAT 2.5",
    "ESKI RESAT 5",
  ],
  gramlar: [
    "0.25 Paketli (22)",
    "0.50 Paketli (22)",
    "0.50 Kulplu (22)",
    "1 Gr Paketli (22)",
    "1 Gr Kulplu (22)",
    "2 Gr Kulplu (22)",
    "0.5 Gr (24)",
    "1 Gr (24)",
    "2 Gr (24)",
    "2.5 Gr (24)",
    "5 Gr (24)",
    "10 Gr (24)",
    "20 Gr (24)",
    "50 Gr (24)",
    "100 Gr (24)",
    "250 Gr (24)",
    "500 Gr (24)",
  ],
  pariteler: [
    "GBPUSD",
    "USDCHF",
    "AUDUSD",
    "USDDKK",
    "USDCAD",
    "USDJPY",
    "USDSAR",
    "USDSEK",
    "USDNOK",
  ],
};

export const seperateCursWithSplice = (curArray, source) => {
  const currData = [];
  const mineData = [];
  const sarrData = [];
  const gramData = [];
  const pariteData = [];
  let lastCurr;
  let lastMine;
  let lastSarr;
  let lastGram;
  let lastParite;
  let allCurs = {};
  if (source === "FOREX") {
    allCurs = forexAllCurs;
  } else if (source === "HAREM") {
    allCurs = haremAllCurs;
  } else if (source === "SAGLAMOGLU") {
    allCurs = saglamAllCurs;
  }
  for (let i = 0; i < curArray.length; i++) {
    if (allCurs["currencies"].includes(curArray[i]["symbol"])) {
      const curIndex = allCurs["currencies"].indexOf(curArray[i]["symbol"]);
      if (curIndex === allCurs["currencies"].length - 1) {
        lastCurr = curArray[i];
      }
      currData.splice(curIndex, 0, curArray[i]);
      // currData[curIndex] = curArray[i];
      // currData.push(curArray[i]);
    }
    if (allCurs["mines"].includes(curArray[i]["symbol"])) {
      const curIndex = allCurs["mines"].indexOf(curArray[i]["symbol"]);
      if (curIndex === allCurs["mines"].length - 1) {
        lastMine = curArray[i];
      }
      mineData.splice(curIndex, 0, curArray[i]);
      // mineData[curIndex] = curArray[i];
      // mineData.push(curArray[i]);
    }
    if (allCurs["sarrafiyeler"].includes(curArray[i]["symbol"])) {
      const curIndex = allCurs["sarrafiyeler"].indexOf(curArray[i]["symbol"]);
      if (curIndex === allCurs["sarrafiyeler"].length - 1) {
        lastSarr = curArray[i];
      }
      sarrData.splice(curIndex, 0, curArray[i]);
      // sarrData[curIndex] = curArray[i];
      // sarrData.push(curArray[i]);
    }
    if (allCurs["gramlar"].includes(curArray[i]["symbol"])) {
      const curIndex = allCurs["gramlar"].indexOf(curArray[i]["symbol"]);
      if (curIndex === allCurs["gramlar"].length - 1) {
        lastGram = curArray[i];
      }
      gramData.splice(curIndex, 0, curArray[i]);
      // gramData[curIndex] = curArray[i];
      // gramData.push(curArray[i]);
    }
    if (allCurs["pariteler"].includes(curArray[i]["symbol"])) {
      const curIndex = allCurs["pariteler"].indexOf(curArray[i]["symbol"]);
      if (curIndex === allCurs["pariteler"].length - 1) {
        lastParite = curArray[i];
      }
      pariteData.splice(curIndex, 0, curArray[i]);
      // pariteData[curIndex] = curArray[i];
      // pariteData.push(curArray[i]);
    }
  }
  currData.push(currData.splice(currData.indexOf(lastCurr), 1)[0]);
  mineData.push(mineData.splice(mineData.indexOf(lastMine), 1)[0]);
  sarrData.push(sarrData.splice(sarrData.indexOf(lastSarr), 1)[0]);
  if (source !== "HAREM") {
    gramData.push(gramData.splice(gramData.indexOf(lastGram), 1)[0]);
  }
  if (source !== "FOREX") {
    pariteData.push(pariteData.splice(pariteData.indexOf(lastParite), 1)[0]);
  }
  return { currData, mineData, sarrData, gramData, pariteData };
};

export const seperateCursWithPush = (curArray, source) => {
  const currData = [];
  const mineData = [];
  const sarrData = [];
  const gramData = [];
  const pariteData = [];
  let allCurs = {};
  if (source === "FOREX") {
    allCurs = forexAllCurs;
  } else if (source === "HAREM") {
    allCurs = haremAllCurs;
  } else if (source === "SAGLAMOGLU") {
    allCurs = saglamAllCurs;
  }

  for (let i = 0; i < allCurs["currencies"].length; i++) {
    const curIndex = curArray.findIndex(
      (item) => item.symbol === allCurs["currencies"][i]
    );
    if (curIndex !== -1) {
      currData.push(curArray[curIndex]);
    }
  }

  for (let i = 0; i < allCurs["mines"].length; i++) {
    const curIndex = curArray.findIndex(
      (item) => item.symbol === allCurs["mines"][i]
    );
    if (curIndex !== -1) {
      mineData.push(curArray[curIndex]);
    }
  }

  for (let i = 0; i < allCurs["sarrafiyeler"].length; i++) {
    const curIndex = curArray.findIndex(
      (item) => item.symbol === allCurs["sarrafiyeler"][i]
    );
    if (curIndex !== -1) {
      sarrData.push(curArray[curIndex]);
    }
  }

  for (let i = 0; i < allCurs["gramlar"].length; i++) {
    const curIndex = curArray.findIndex(
      (item) => item.symbol === allCurs["gramlar"][i]
    );
    if (curIndex !== -1) {
      gramData.push(curArray[curIndex]);
    }
  }

  for (let i = 0; i < allCurs["pariteler"].length; i++) {
    const curIndex = curArray.findIndex(
      (item) => item.symbol === allCurs["pariteler"][i]
    );
    if (curIndex !== -1) {
      pariteData.push(curArray[curIndex]);
    }
  }

  return { currData, mineData, sarrData, gramData, pariteData };
};
