import { useState, React, useContext } from "react";
import PropTypes from "prop-types";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import Clock from "react-live-clock";
import ThemeContext from "../../../context/ThemeModeCtx";
import "./NavBar.css";
import logo from "../../../assets/LOGO.png";
import nightMode from "../../../assets/NIGHT_MODE.png";
import lightMode from "../../../assets/LIGHT_MODE.png";
import clockLogo from "../../../assets/CLOCK.png";
import clockLogoDark from "../../../assets/CLOCK_DARK.png";
import TextSlider from "./TextSlider";
import Converter from "../../converter/Converter";

export const NavBar = ({ onClickToptan, onClickPar, userSource }) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  const [dispConv, setDispConv] = useState("sarrafiye");

  const onClick = (x) => (e) => {
    setDispConv(x);
  };

  var today = new Date();
  var calendar =
    today.getDate() +
    "." +
    parseInt(today.getMonth() + 1) +
    "." +
    today.getFullYear();
  const [passive, setPassive] = useState(true);
  const selectionChangeToptan = () => {
    if (passive) {
      setPassive(false);
    }

    onClickToptan();
  };
  const selectionChangePar = () => {
    if (!passive) {
      setPassive(true);
    }

    onClickPar();
  };
  console.log(theme);

  return (
    <AppBar
      position="fixed"
      id="AppBar"
      sx={{
        ...(theme === "dark"
          ? { backgroundColor: "#202224" }
          : { backgroundColor: "transparent" }),
      }}
    >
      <Toolbar
        variant="dense"
        className="toolbar"
        id="toolbar"
        sx={{ ...(theme === "dark" && { backgroundColor: "#D8CD9A" }) }}
      >
        <div className="logo-wrapper">
          <img src={logo} className="logo" alt=""></img>
        </div>
        <h1
          className="brand-header"
          //style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
        >
          FİKRİ ŞAHİN KUYUMCULUK
        </h1>
        <div className="content-wrapper">
          <div className="toolbar-header">
            <div className="converterContainer">
              <div style={{ display: "flex" }}>
                <h3
                  className="converterHeaderText"
                  //         style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                >
                  ÇEVİRİCİ
                </h3>
                <h5
                  //       style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                  className="converterSelection"
                  onClick={onClick("currency")}
                >
                  Doviz
                </h5>
                <h5
                  className="converterSelection"
                  //     style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                  onClick={onClick("mine")}
                >
                  Maden
                </h5>
                {userSource === "FOREX" ? null : (
                  <h5
                    //     style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                    className="converterSelection"
                    onClick={onClick("parite")}
                  >
                    Parite
                  </h5>
                )}
                <h5
                  // style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                  className="converterSelection"
                  onClick={onClick("sarrafiye")}
                >
                  Sarrafiye
                </h5>
                {userSource === "HAREM" ? null : (
                  <h5
                    className="converterSelection"
                    // style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                    onClick={onClick("gram")}
                  >
                    Gram Altın
                  </h5>
                )}
              </div>
              <hr className="converter-divider"></hr>
              <Converter convType={dispConv} />
            </div>
            <div className="utilities-wrapper">
              <div className="dates">
                <div className="calendar" style={{ display: "flex" }}>
                  <CalendarTodayIcon
                    style={{
                      fontSize: "40px",
                      //    ...(theme === "dark" && { color: "white" }),
                    }}
                  />
                  <div
                    className="clock"
                    // style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                  >
                    {calendar}
                  </div>
                </div>
                <img className="clock-logo" src={clockLogo}></img>
                <Clock
                  format={"HH:mm:ss"}
                  ticking={true}
                  className="clock"
                  // style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                ></Clock>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="menu">
              <h2
                // style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                onClick={selectionChangeToptan}
                className={`${!passive ? "passive" : ""}`}
              >
                TOPTAN
              </h2>
              <h2
                //style={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
                onClick={selectionChangePar}
                className={`${passive ? "passive" : ""}`}
              >
                PERAKENDE
              </h2>
              <div className="mode-switch-wrapper">
                <img
                  className="night-logo"
                  src={nightMode}
                  alt=""
                  onClick={themeCtx.changeTheme}
                ></img>
                <img
                  className="light-logo"
                  src={lightMode}
                  alt=""
                  onClick={themeCtx.changeTheme}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </Toolbar>
      <TextSlider />
    </AppBar>
  );
};

NavBar.propTypes = {
  onClickToptan: PropTypes.func.isRequired,
  onClickPar: PropTypes.func.isRequired,
  userSource: PropTypes.string.isRequired,
};

export default NavBar;
