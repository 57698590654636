import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ThemeContext from "../context/ThemeModeCtx";
import "./Home.css";
import { getCur } from "../actions/curActions";
import Footer from "../components/layout/footer/Footer";
import SourceDecider from "../functions/SourceDecider";

export const Home = ({ getCur }) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  useEffect(() => {
    getCur();
    const interval = setInterval(() => getCur(), 7000);
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div
      style={{
        backgroundColor: theme === "dark" ? "#232325" : " transparent",
        marginTop: 0,
      }}
    >
      <SourceDecider />
      <Footer />
    </div>
  );
};

Home.propTypes = {
  getCur: PropTypes.func.isRequired,
};

export default connect(null, { getCur })(Home);
