import React, { useState, useEffect } from "react";
import Ticker from "react-ticker";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { calcCur } from "../../../functions/priceCalculator";
import { setCurrencyDisp } from "../../../functions/dispNameIconSetter";
import "./TextSlider.css";

export const TextSlider = ({ currData }) => {
  const [index, setIndex] = useState(0);
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    if (currData.length > 0) {
      if (typeof currData[0].symbol !== "undefined") {
        setShow(true);
      }
    } else {
      setShow(false);
    }
  }, [currData]);
  useEffect(() => {
    console.log(isShow);
  }, [isShow]);

  function getIndex(index) {
    let indexHolder = 0;
    if (index >= currData.length - 1) {
      indexHolder = 0;
      setIndex(0);
      let sliderTextHolder =
        currData[indexHolder].symbol.slice(0, 3) +
        "/" +
        currData[indexHolder].symbol.slice(3);
      sliderTextHolder =
        sliderTextHolder +
        " " +
        calcCur(currData[indexHolder])["sellValue"].toFixed(3);
      return sliderTextHolder + ",";
    } else {
      indexHolder = index + 1;
      setIndex(indexHolder);
      let sliderTextHolder =
        currData[indexHolder].symbol.slice(0, 3) +
        "/" +
        currData[indexHolder].symbol.slice(3);
      sliderTextHolder =
        sliderTextHolder +
        " " +
        calcCur(currData[indexHolder])["sellValue"].toFixed(3);
      return sliderTextHolder + ",";
    }
  }

  return (
    <div className="container-app-slider">
      {isShow ? (
        <Ticker>
          {() => (
            <>
              <p
                style={{ marginLeft: "30px" }}
                className="text-slider-currency-text"
              >
                {getIndex(index)}
              </p>
            </>
          )}
        </Ticker>
      ) : (
        <p>Loading</p>
      )}
    </div>
  );
};

TextSlider.propTypes = {
  currData: PropTypes.array,
};

const mapStateToProps = (state) => ({
  currData: state.curState.curr,
});

export default connect(mapStateToProps)(TextSlider);
