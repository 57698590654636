import { SET_LOADING, CUR_ERROR, GET_CUR } from "./types";
import axios from "axios";
import {
  seperateCursWithPush,
  seperateCursWithSplice,
} from "../functions/curSeperator";

export const getCur = () => async (dispatch) => {
  const url_local = "http://0.0.0.0:5000/api/v1/get-all-data/";
  const url_aws = "http://54.196.155.17:5000/api/v1/get-all-data/";
  const url_natro = "http://37.148.213.138:5000/api/v1/get-all-data/";
  const url_netlify = "/flask/api/v1/get-all-data/";
  const adminUrl_local = "http://127.0.0.1:8000/api/v1/currency/get_all/";
  const adminUrl_aws = "http://3.83.106.36:8000/api/v1/currency/get_all/";
  const adminUrl_natro = "http://37.148.213.138:8000/api/v1/currency/get_all/";
  const adminUrl_netlify = "/dj/api/v1/currency/get_all/";

  try {
    setLoading();
    //get admin data
    const adminRes = await axios({
      method: "post",
      url: adminUrl_netlify,
      data: {
        user_id: 2,
      },
    });
    const adminDataStr = await adminRes.data;
    const adminData = JSON.parse(JSON.parse(adminDataStr)["data"]);
    const userSource = adminData[0]["fields"]["source"];
    //get flask data
    const res = await axios({
      method: "post",
      url: url_netlify,
      data: { data_source: userSource },
    });
    const dataStr = await res.data["data"];
    const data = JSON.parse(dataStr);
    // take has from harem if source is forex
    let haremHas = {};
    if (userSource === "FOREX") {
      const haremRes = await axios({
        method: "post",
        url: url_netlify,
        data: { data_source: "HAREM" },
      });
      const haremDataStr = await haremRes.data["data"];
      const haremData = JSON.parse(haremDataStr);
      const hasIndex = haremData.findIndex((item) => item.symbol === "HAS");
      haremHas = haremData[hasIndex];
    }
    //add is_active ones to state
    const stateData = [];
    const goldCalcData = [];
    for (let i = 0; i < adminData.length; i++) {
      if (adminData[i]["fields"]["symbol"] === "USDTRY") {
        goldCalcData[0] = data[i];
      } else if (adminData[i]["fields"]["symbol"] === "ONS") {
        goldCalcData[1] = data[i];
      } else if (adminData[i]["fields"]["symbol"] === "HAS") {
        // goldCalcData[2] = data[i];
        if (userSource === "FOREX") {
          goldCalcData[2] = haremHas;
        } else {
          goldCalcData[2] = data[i];
        }
      }
      if (adminData[i]["fields"]["is_active"]) {
        const curDatum = {
          symbol: data[i]["symbol"],
          buy:
            userSource === "FOREX" && data[i]["symbol"] === "HAS"
              ? haremHas["buy"]
              : data[i]["buy"],
          sell:
            userSource === "FOREX" && data[i]["symbol"] === "HAS"
              ? haremHas["sell"]
              : data[i]["sell"],
          buy_open:
            userSource === "FOREX" && data[i]["symbol"] === "HAS"
              ? haremHas["buy_open"]
              : data[i]["buy_open"],
          sell_open:
            userSource === "FOREX" && data[i]["symbol"] === "HAS"
              ? haremHas["sell_open"]
              : data[i]["sell_open"],
          buy_percentage: adminData[i]["fields"]["buy_percentage"],
          sell_percentage: adminData[i]["fields"]["sell_percentage"],
          tl_buy: adminData[i]["fields"]["tl_buy"],
          tl_sell: adminData[i]["fields"]["tl_sell"],
          is_percentage: adminData[i]["fields"]["is_percentage"],
          milyem_buy: adminData[i]["fields"]["milyem_buy"],
          milyem_sell: adminData[i]["fields"]["milyem_sell"],
          milyem_fast_buy: adminData[i]["fields"]["milyem_fast_buy"],
          milyem_fast_sell: adminData[i]["fields"]["milyem_fast_sell"],
          is_fast_milyem: adminData[i]["fields"]["is_fast_milyem"],
          milyem_bulk_buy: adminData[i]["fields"]["milyem_bulk_buy"],
          milyem_bulk_sell: adminData[i]["fields"]["milyem_bulk_sell"],
          weight: adminData[i]["fields"]["weight"],
        };
        stateData.push(curDatum);
      }
    }
    // console.log(stateData);
    // const curData = seperateCursWithSplice(stateData, userSource);
    const curData = seperateCursWithPush(stateData, userSource);
    // console.log(goldCalcData);
    dispatch({
      type: GET_CUR,
      currPayload: curData,
      goldCalcPayload: goldCalcData,
      sourcePayload: userSource,
    });
  } catch (err) {
    dispatch({
      type: CUR_ERROR,
      payload: err,
    });
  }
};

export const setLoading = () => {
  return {
    type: SET_LOADING,
  };
};
