import { SET_LOADING, CUR_ERROR, GET_CUR } from "../actions/types";

const initialState = {
  currPrev: [],
  minePrev: [],
  sarrPrev: [],
  gramPrev: [],
  paritePrev: [],
  goldCalcDataPrev: [],
  curr: [],
  mine: [],
  sarr: [],
  gram: [],
  parite: [],
  goldCalcData: [],
  source: "",
  loading: false,
  error: null,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUR:
      return {
        ...state,
        loading: false,
        currPrev: state.curr,
        minePrev: state.mine,
        sarrPrev: state.sarr,
        gramPrev: state.gram,
        paritePrev: state.parite,
        goldCalcDataPrev: state.goldCalcData,
        curr: action.currPayload.currData,
        mine: action.currPayload.mineData,
        sarr: action.currPayload.sarrData,
        gram: action.currPayload.gramData,
        parite: action.currPayload.pariteData,
        goldCalcData: action.goldCalcPayload,
        source: action.sourcePayload,
      };
    case CUR_ERROR:
      console.error(action.payload);
      return {
        ...state,
        error: action.payload,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
