import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Select, MenuItem, TextField } from "@mui/material";
import ThemeContext from "../../context/ThemeModeCtx";
import { makeStyles } from "@mui/styles";

import "./Converter.css";
import { setGramDisp } from "../../functions/dispNameIconSetter";
import { calcGram, calcCur } from "../../functions/priceCalculator";

export const GramConverter = ({ curData, goldCalcData, userSource }) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx.theme;
  const useStyles = makeStyles({
    input: {
      color: theme === "dark" && "#DEDEDE !important",
    },
  });
  const classes = useStyles();
  const customStyles = {
    control: () => ({
      display: "flex",
      alignItems: "center",
      border: 0,
      padding: 0,
      height: "auto",
      background: "red",
      "&:hover": {
        boxShadow: "none",
      },
    }),
  };
  const [cur, setCur] = useState(curData[0]["symbol"]);
  const [input, setInput] = useState(0);
  const curIndex = curData.findIndex((item) => item.symbol === cur);
  let prices = { buyValue: 0, sellValue: 0, change: 0 };
  if (userSource === "FOREX" && !(curData[curIndex]["symbol"] === "HAS")) {
    prices = calcGram(curData[curIndex], goldCalcData);
  } else {
    prices = calcCur(curData[curIndex]);
  }
  const onCurChange = (event) => {
    setCur(event.target.value);
    setInput(0);
  };
  const onInputChange = (event) => {
    setInput(event.target.value);
  };
  return (
    <div className="converterWrapper">
      <div className="input-container">
        <Select
          value={cur}
          onChange={onCurChange}
          styles={customStyles}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          //         sx={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
        >
          {curData?.map((value) => (
            <MenuItem key={curData.indexOf(value)} value={value["symbol"]}>
              <img src={setGramDisp(value["symbol"])["icon1"]} alt="" />
              {setGramDisp(value["symbol"])["cur1"]}
            </MenuItem>
          ))}
        </Select>
        <TextField
          className="deneme"
          type="number"
          onChange={onInputChange}
          value={input}
          sx={{ m: 0, p: 0 }}
          //       inputProps={{ className: classes.input }}
        />
        <Select
          className="deneme"
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value="TRY"
          //     sx={{ ...(theme === "dark" && { color: "#F9F9F9" }) }}
        >
          <MenuItem
            className="deneme"
            sx={{ p: 0, m: 0 }}
            style={{ padding: 0 }}
            value="TRY"
          >
            <img src="assets/currency_and_mine_icons/TRY.png" alt="" /> TRY
          </MenuItem>
        </Select>
      </div>
      <div className="results-container">
        <TextField
          // inputProps={{ className: classes.input }}
          //   InputLabelProps={{
          // sx: { ...(theme === "dark" && { color: "#F9F9F9 !important" }) },
          //}}
          value={(prices["buyValue"] * input)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          label="Alis Fiyati"
          className="deneme"
        />
        <TextField
          //   inputProps={{ className: classes.input }}
          //    InputLabelProps={{
          //      sx: { ...(theme === "dark" && { color: "#F9F9F9 !important" }) },
          // }}
          className="deneme"
          value={(prices["sellValue"] * input)
            .toFixed(0)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          label="Satis Fiyati"
        />
      </div>
    </div>
  );
};

GramConverter.propTypes = {
  curData: PropTypes.array,
  goldCalcData: PropTypes.array,
  userSource: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  curData: state.curState.gram,
  goldCalcData: state.curState.goldCalcData,
  userSource: state.curState.source,
});

export default connect(mapStateToProps)(GramConverter);
